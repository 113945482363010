@import '../../../styles//colours.scss';

.controls-container {
  display: flex;
  flex-direction: column;
}

.control-content {
  width: 84px;
  height: 34px;
  text-align: center;
  font-family: monospace;
  font-size: 16px;
  line-height: 34px;

  &.disabled {
    color: $dark-grey;
  }
}