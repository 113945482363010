.image-viewer-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.image-viewer-uploader {
  width: fit-content;
  margin: 192px auto;
}
