@import '../../../styles/colours.scss';

$width: 64px;
$height: $width;

.icon {
  box-sizing: border-box;
  position: relative;
  width: $width;
  height: $height;
  background-color: $black;
  font-family: monospace;
  font-size: calc($width * 0.72);
  line-height: calc($width * 0.72);
  font-weight: bolder;
  color: magenta;
  text-shadow: calc($width / 32) calc($width / 16) calc($width / 16) cyan;
  overflow: hidden;
  
  .planet {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc($width / 2);
    height: calc($height / 2);
    transform: translate(-50%, 50%);
    background-color: magenta;
    box-shadow: 0 0 calc($width / 4) calc($width / 16) magenta;
    border-radius: 50%;
  }

  .p {
    position: absolute;
    top: 0;
    left: calc($width / 8);
  }

  .j {
    position: absolute;
    right: calc($width / 10);
    bottom: calc($width / 16);
  }
}