@import '../../../styles//colours.scss';

.amiga-window {
  box-sizing: border-box;
  position: relative;
  border-top: 2px solid $white;
  border-left: 2px solid $white;
  border-right: 2px solid $black;
  border-bottom: 2px solid $black;
  background-color: $blue;
}

.amiga-window-title {
  padding: 8px 8px;
  font-family: monospace;
  font-weight: bolder;
  font-size: 18px;
  line-height: 8px;
}
$top: 24px;
$bottom-margin: 4px;
.amiga-inner-window {
  box-sizing: border-box;
  position: absolute;
  top: $top;
  left: 4px;
  border-top: 2px solid $black;
  border-left: 2px solid $black;
  border-right: 2px solid $white;
  border-bottom: 2px solid $white;
  background-color: $grey;
  height: calc(100% - $top - $bottom-margin);
  width: calc(100% - 8px);
}
