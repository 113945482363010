.iff2png {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 770px;
}

.controls-container {
  display: flex;
  flex-direction: column;
}
