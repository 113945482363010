@import '../../../styles//colours.scss';

.amiga-icon {
  box-sizing: border-box;
  padding: 4px;
  width: max-content;
  height: max-content;
  background-color: $grey;
  border-top: 2px solid $white;
  border-left: 2px solid $white;
  border-right: 2px solid $black;
  border-bottom: 2px solid $black;
  cursor: pointer;

  &.clicked {
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
  }
}
