.file-uploader {
  display: inline-block;
  width: max-content;
}

.file-uploader-icon-container {
  margin-left: 14px;
}

.file-uploader-content {
  width: max-content;
  margin: auto;
  margin-top: 8px;
  & > svg {
    width: 64px;
    height: 64px;
  }
}

.file-uploader-text {
  display: inline-block;
  margin-top: 4px;
  font-family: monospace;
  text-align: center;
}