
.meta-data-field {
  display: inline-block;
  position: relative;
  width: 320px;
  height: 24px;
  font-family: monospace;
  font-size: 16px;
  line-height: 16px;
  padding: 4px;
}

.meta-data-field-title {
  position: absolute;
  left: 0;
  font-weight: bolder;
}

.meta-data-field-value {
  position: absolute;
  right: 0;
}
