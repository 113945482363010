@import '../../../styles//colours.scss';

.meta-data-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 8px;
}

.meta-data-no-image {
  width: fit-content;
  margin: 32px auto;
  font-family: monospace;
  font-size: 32px;
  color: $dark-grey;
}
