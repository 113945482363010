@import '../../../styles//colours.scss';

.palette-colours {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.palette-colour {
  display: inline-block;
}

.palette-no-image {
  width: fit-content;
  margin: 128px auto;
  font-family: monospace;
  font-size: 24px;
  text-align: center;
  color: $dark-grey;
}
