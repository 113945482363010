@import './styles/colours.scss';

body {
  background-color: $grey;
}

.header {
  box-sizing: border-box;
  margin: 16px;
  height: 24px;
  padding: 4px;
  background-color: $black;
  color: $grey;
  font-size: 16px;
  line-height: 16px;
  font-family: monospace;
  font-weight: bold;
}

.footer {
  display: flex;
  width: 400px;
  margin: 32px auto;
  gap: 32px;
  font-family: monospace;
  font-size: 20px;
  line-height: 64px;
  text-align: center;
  color: $dark-grey;
}
